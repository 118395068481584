import {
  toImageProps,
  toLinkProps,
  PartnersWidget,
  TPartnersWidgetTab,
  EPartnersWidgetTabsIconPosition,
} from '@front/shared/ds';
import { useRouter } from 'next/router';
import React from 'react';

import { useGetPartnersWidgetProductsQuery } from '../../../features/partnersWidget';

import type { Section, PartnersWidgetProduct } from '@shared/master-types';

export type TPartnersWidgetConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'partners-widget' }
>;

export const PartnersWidgetConnected: React.FC<
  TPartnersWidgetConnectedProps
> = props => {
  const {
    product,
    allTabLabel,
    moreLabel,
    tabsStickyThreshold,
    visibleThreshold,
    showTabs,
    showTabsIcon,
    tabsIconPosition,
  } = props;
  const widgetTabs: TPartnersWidgetTab[] = [];

  const router = useRouter();
  const currentLocale = String(router.query.locale || '');

  let ids: string[] = [];
  if (typeof product !== 'string') {
    ids = (product.connections || []).map(p =>
      typeof p === 'string' ? p : p.id,
    );
  }

  const partnerWidgetProductsQuery: Parameters<
    typeof useGetPartnersWidgetProductsQuery
  >[0] = {
    locale: currentLocale,
    draft: false,
    page: 1,
    limit: ids.length,
    where: {
      id: { in: ids },
    },
  };

  const { data } = useGetPartnersWidgetProductsQuery(
    partnerWidgetProductsQuery,
    { skip: !ids.length },
  );

  if (!data?.docs?.length) {
    return null;
  }

  const productsMap: Record<string, PartnersWidgetProduct> = data.docs.reduce(
    (acc, cur) => ({ ...acc, [cur.id]: cur }),
    {},
  );

  // map ids to preserve order
  ids.forEach(id => {
    const p = productsMap[id];

    if (p && typeof p !== 'string' && typeof p.type !== 'string') {
      const tabIndex = widgetTabs.findIndex(
        tab => typeof p.type !== 'string' && tab.text === p.type.title,
      );

      const newCard = {
        imageProps: p.image ? toImageProps(p.image) : null,
        iconProps: p.type.icon ? toImageProps(p.type.icon) : null,
        linkProps: p.link[0] ? toLinkProps('', p.link[0]) : null,
        description: p.type.title,
      };

      if (tabIndex === -1) {
        widgetTabs.push({
          text: p.type.title,
          iconProps:
            p.type.icon && showTabsIcon ? toImageProps(p.type.icon) : null,
          cards: [newCard],
        });
      } else {
        widgetTabs[tabIndex].cards.push(newCard);
      }
    }
  });

  return (
    <PartnersWidget
      tabs={widgetTabs}
      showTabs={showTabs}
      tabsIconPosition={tabsIconPosition as EPartnersWidgetTabsIconPosition}
      allTabLabel={allTabLabel}
      moreLabel={moreLabel}
      tabsStickyThreshold={tabsStickyThreshold}
      visibleThreshold={visibleThreshold}
    />
  );
};

export default PartnersWidgetConnected;
